import request from './axios'
export function getRecruitTable(params){
    return request({
        url:'api/weixin/patientList',
        method:'POST',
        // params:{
        //     researchCode
        // }
        params
    })
}
//拒绝接口
export function getRefuse(centerCode,openid){
    return request({
        url:'api/weixin/refuse',
        method:'POST',
        params:{
            centerCode,
            openid
        }
    })
}