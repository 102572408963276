import Vue from 'vue'
import request from './axios'
//数据看板
  //饼图
export function getPie(params) { // 
  return request({
    url: '/api/homepage/ShowResearch',
    method: 'GET',
    params,
  })
}
  //中心柱状图
export function getBarCenter(params) { // 
  return request({
    url: '/api/homepage/ShowCenterPatient',
    method: 'GET',
    params,
  })
}
  //月份柱状图
export function getBarMonth(params) { // 
  return request({
    url: '/api/homepage/ShowCenterPatientTime',
    method: 'GET',
    params,
  })
}