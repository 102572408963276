<!--
* @description 
* @fileName recruitment.vue
* @author 孟建伟
* @date 2020/12/03 14:47:39
* @version V1.1.0
!-->
<template>
  <div>
      <el-form :inline="true"  class="demo-form-inline " style="float: right;margin-bottom:-20px">
          <el-form-item  >
              <el-input style="margin-top:13px;" placeholder="请输入内容" size="mini" v-model="search.queryVal" class="input-with-select">
                  <el-select  style="width: 100px;"  v-model="search.selectOption" slot="prepend" placeholder="请选择">
                      <el-option label="受试者编号" value="outpatientNumber" ></el-option>
                      <el-option v-for="(item,index) in labelData"
                                 :key="index"
                                 :label="item.previewMessages"
                                 :value="item.code">
                      </el-option>
                  </el-select>

                  <i @click="getTableData('query')" slot="suffix" class="el-input__icon el-icon-search"></i>
                  <!--@click="searchTestee <el-button @click="searchTestee"  slot="append"  style="height: 24px;line-height: 24px;border: 0; border-radius: 2px;padding:1px 16px 0px;" type="primary" size="small" icon="el-icon-search"></el-button>-->
              </el-input>

          </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-class-name="tableStyle"
        :header-cell-style="{color:'#000'}"
        >
        <!-- <el-table-column
            prop="code"
            label="预筛选号"
            align="center"
            show-overflow-tooltip
            >
        </el-table-column> -->
        <el-table-column
            v-for="(item,index) in labelData"
            :key="index"
            :prop="item.code"
            :label="item.previewMessages"
            align="center"
            show-overflow-tooltip
            >
            <template slot-scope="scope">
                <div>
                 {{ scope.row[item.code]&&( scope.row[item.code].previewMessages === '性别'?formateSex(scope.row[item.code].value):scope.row[item.code].value||'') }} 
                </div>
            </template>
        </el-table-column>
            
        <el-table-column
            prop="address"
            label="操作"
             fixed="right"
             width="160"
            align="center"
            >
            <template  slot-scope="scope">
                <div v-if="{... Object.values(scope.row)[0]}.agree === null">
                    <el-button 
                     type="primary" 
                     size="mini" 
                     @click="openAgree(scope.row,scope.$index)">同意</el-button>
                    <el-button 
                    type="primary" 
                    size="mini"
                    @click="reFuse(scope.row,scope.$index)">拒绝</el-button>
                </div>
                <div v-else>
                    <span v-if="{... Object.values(scope.row)[0]}.agree === 1"> 已同意</span>
                    <span v-else>已拒绝</span>
                </div>
            </template>
        </el-table-column>
     </el-table>

     <!-- 同意窗口 -->
     <!-- 添加受试者 -->
     <el-dialog title="添加受试者" @close="handleClose('testeeFormAdd')" :visible.sync="dialogVisibleAdd" width="35%"
                   custom-class="testee-dialog"
                   @open="submitButtonDisabled = false">
            <el-form size="mini" ref="testeeFormAdd" :model="testeeForm" :rules="testeeRulesAdd" label-width="80px" >
                <el-form-item label="受试者编号" label-width="100px" prop="outpatientNumber">
                    <el-input :disabled="addTesteeType===1" style="width:85%;"
                              :placeholder="addTesteeType===1 ? '您已选择系统生成，无需填写' : '请输入受试者编号'"
                              v-model="testeeForm.outpatientNumber"></el-input>
                </el-form-item>
                <el-form-item v-for="item in testeeDetail" :key="item.pCode" :label="item.pName" label-width="100px"
                              :prop="item.pCode">
                    <el-radio-group v-if="item.pCode==='sex'" v-model="testeeForm[item.pCode]">
                        <el-radio label="01">男</el-radio>
                        <el-radio label="02">女</el-radio>
                    </el-radio-group>
                    <el-select v-model="testeeForm[item.pCode]" placeholder="请选择" v-else-if="item.pCode === 'patientRelation'">
                      <el-option
                        v-for="(item,index) in  patientRelationSelect"
                        :key="index"
                        :label="item"
                        :value="index">
                      </el-option>
                    </el-select>
                    <el-date-picker type="date"
                                    v-else-if="item.pCode==='loginTime'||item.pCode==='dob' ||item.pCode ==='admissionDate'"
                                    v-model="testeeForm[item.pCode]" placeholder="请选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    <el-input v-else style="width:85%;" :placeholder="'请输入'+item.pName"
                              v-model="testeeForm[item.pCode]"></el-input>
                    <el-tooltip v-if="item.pCode==='nameAcronym'" class="item" effect="light"
                                popper-class="testeeAddPopper"
                                content="受试者姓名缩写，按照国家汉语拼音标准方案填写受试者的拼音缩写；两字姓名填写前两个字母；三字姓名填写三字首字母及第三字的第二个字母，四字姓名填写每个字的首字母。如张君ZHJU，王小鹏WXPE，欧阳小雅OYXY；如果不符合上述规则，相应字母位置可用“-”代替。"
                                placement="bottom-end">
                        <span style="display:inline-block;width:14px;height:14px;line-height:14px;text-align:center;font-size:12px;border-radius:7px;background:#ccc;color:#fff;margin-left:4px;">?</span>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-for="(item,index) in testeeForm.testeeGroup"
                              :key="item.groupName"
                              :prop="'testeeGroup.'+index+'.groupValue'"
                              :rules="[{required:true,message:'请选择'+item.groupName,trigger: 'blur,change'}]"
                              :label="item.groupName"
                              label-width="100px">
                    <el-select v-model="item.groupValue"
                               :placeholder="'请选择'+item.groupName">
                        <el-option v-for="groupItem in item.data"
                                   :key="groupItem.code"
                                   :label="groupItem.dictName"
                                   :value="groupItem.code">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisibleAdd = false">取 消</el-button>
				<el-button size="mini" type="primary" :disabled="submitButtonDisabled"
                           @click="addFormConfirm('testeeFormAdd')" :loading="btnLoading">确 定</el-button>
			</span>
     </el-dialog>
  </div>
</template>

<script>
import {
        addTestee,
        getTesteeSetting
    } from "@/api/testee";
import {getRecruitTable,getRefuse} from  "@/api/RecruitTabel";
import {getVisit} from  "@/api/setting";
export default {
  components: {},
  props: {},
  data () {
    return {
        search:{
            selectOption:"",
            queryVal:""
        },
        diaAgree:false,
        dialogVisibleAdd:false,
        submitButtonDisabled:false,
        btnLoading:false,
        researchCode:this.$route.query.researchCode,
         testeeRulesAdd: {
                    nameAcronym: [
                        {required: true, message: '请输入姓名缩写', trigger: 'change,blur'},
                        {pattern: /^[A-Z]{4}$/, message: '请输入 4 位大写字母'}
                    ],
                    outpatientNumber: [
                        {required: true, message: '请输入受试者编号', trigger: 'change,blur'},
                        {pattern: /^[A-Za-z0-9]{1,200}$/, message: '请输入 200 位以内数字或字母'},
                    ],
                    sex: [
                        {required: true, message: '请选择性别', trigger: 'blur,change'}
                    ],
                    idNum: [
                        {required: true, message: '请输入身份证号', trigger: 'change,blur'},
                        {
                            pattern: /^[1-9]\d{7}((0\d)|bai(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
                            message: '请输入正确的身份证号码'
                        }
                    ],
                    homephone: [
                        {required: true, message: '请输入手机号', trigger: 'change,blur'},
                        {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码'}
                    ],
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'change,blur'},
                        {min: 1, max: 25, trigger: 'change,blur'},
                        // {
                        //     pattern: /(?:[\u4E00-\u9FFF]{1,8}·bai\u4E00-\u9FFF]{1,8})|(?:[\u4E00-\u9FFF]{2,5})/,
                        //     message: '请输入正确的姓名'
                        // }
                    ],
                    cardNum: [
                        {required: true, message: '请输入就诊卡号', trigger: 'change,blur'},
                        {pattern: /^[0-9a-zA-Z]{1,25}$/, message: '请输入正确的就诊卡号'}
                    ],
                    age: [
                        {required: true, message: '请输入年龄', trigger: 'change,blur'},
                        {pattern: /^\d{1,3}$/, message: '请输入正确的年龄'}
                    ],
                    caseNum: [
                        {required: true, message: '请输入病案号', trigger: 'change,blur'},
                        {pattern: /^[0-9a-zA-Z]{1,25}$/, message: '请输入正确的病案号'}
                    ],
                    loginTime: [
                        {required: true, message: '请选择登记日期', trigger: 'change,blur'}
                    ],
                    dob: [
                        {required: true, message: '请选择出生日期', trigger: 'change,blur'}
                    ],
                },
        testeeForm: {
            outpatientNumber: '',
            nameAcronym: '',
            name: '',
            sex: '',
            idNum: '',
            homephone: '',
            loginTime: '',
            cardNum: '',
            caseNum: '',
            age: '',
            dob: '',
            testeeGroup: [],
        },
        currentOpenid:'',
        currentCenterCode:'',
        testeeGroup:[],
        testeeDetail:[],
        groupMessage:[],
        addTesteeType:'',
        tableData: [],
        labelData:[],
        tablels:[],
        testeeValidate:[],
        patientRelationSelect:[]
    };
  },
  computed: {},
  watch: {},
  mounted (){
      this.getTableData()
      this.getTesteeSettingInfo()
  },
  methods: {
      formateSex(sex){
          const s=  {'01':'男','02':'女'}
          return s[sex]
      },
      getTableData(val){
          let postData={
              researchCode:this.$route.query.researchCode
          }
          if (val){
              let queryKey=this.search.selectOption;
              let queryVal=this.search.queryVal;
              if (queryKey&&queryVal){
                  postData.queryKey=queryKey
                  postData.queryVal=queryVal
              }
          }
          getRecruitTable(postData)
          .then( res => {
            this.labelData = res.data.configList
            this.tableData = res.data.from
          })
          .catch( error => {

          })
      },
      tableStyle(){
          return 'table_style'
      },
      reFuse(v,i){
        let {openid, centerCode} = {... Object.values(v)[0]}
        getRefuse(centerCode,openid)
        .then( res => {
            this.$message.success('拒绝成功')
            this.$store.dispatch('getQueryValue',this.researchCode)
            this.getTableData()
        }).catch( error => {

        })
      },
      openAgree(v,i){
          const curentRow =this.tableData[i]
          Object.values(curentRow).map((e)=>{
              this.testeeDetail.map((d,i)=>{
                  if (d.pName === e.previewMessages) {
                      this.$set(this.testeeForm,d.pCode,e.value)
                  }
              })
          })
          //打开初始化data表单数据
        //   this.testeeForm = this.$options.data.call(this).testeeForm;
          console.log(this.tableData,this.testeeForm,this.testeeDetail);
          let {openid, centerCode} = {... Object.values(v)[0]}
          this.currentOpenid = openid
          this.currentCenterCode = centerCode
          this.testeeForm.testeeGroup = this.groupMessage
          this.$refs.testeeFormAdd && this.$refs.testeeFormAdd.clearValidate()
          this.dialogVisibleAdd = true
      },
      getTesteeSettingInfo() {
        const postData = {
            researchCode: this.$route.query.researchCode,
            versionCode: JSON.parse(localStorage.getItem('versionCode')),
        };
        getTesteeSetting(postData)
            .then(res => {
            // console.log('getTesteeSetting', res);
            this.testeeDetail = res.data.lable;
            this.patientRelationSelect = res.data.patientRelationSelect
            this.addTesteeType = res.data.createFlag;
            this.testeeValidate = res.data.lable.reduce(function (total, item) {
                total.push(item.pCode);
                return total
            }, []);
            //                      this.groupMessage = res.data.groupMessage;
            this.groupMessage = res.data.groupMessage.reduce(function (total, item) {
                item.groupValue = '';
                total.push(item);
                return total
            }, []);
            this.groupMessage.forEach((item, index) => {
                this.testeeValidate.push('testeeGroup.' + index + '.groupValue');
            });
            })
            .catch(error => {

            });
        },
      handleClose(formName) {
        this.$refs[formName].resetFields();
      },
      addFormConfirm(formName) {

        this.btnLoading = true
        let validateDetail = this.addTesteeType === 1 ? this.testeeValidate : this.testeeValidate.concat(['outpatientNumber']);
        let isValid = true;
        let countValid = 0;
        let _this = this;
        //只有一个自动生成的编号是无需检验
        if (validateDetail.length > 0) {
            this.$refs[formName].validateField(validateDetail, (result) => {
            if (!!result) {
                isValid = false;
                this.btnLoading = false
                return false;
            } else {
                countValid++
            }
            if (isValid && countValid == validateDetail.length) {
                let postD = validateDetail.reduce(function (total, item) {
                total[item] = _this.testeeForm[item];
                return total
                }, {});
                // this.submitButtonDisabled = true;
                const postData = {
                    researchCode: this.researchCode,
                    versionCode: JSON.parse(localStorage.getItem('versionCode')),
                    centerKey: this.currentCenterCode,
                    openid:this.currentOpenid,
                    ...postD
                };

                this.addTesteeCommon(postData);
            }
            });
        } else {
            const postData = {
                researchCode: this.researchCode,
                versionCode: JSON.parse(localStorage.getItem('versionCode')),
                centerKey: this.currentCenterCode,
                openid:this.currentOpenid
            };
            this.addTesteeCommon(postData);
        }
      },
      addTesteeCommon(postData) {
        // let groupMsg = []
        let groupMsg = this.testeeForm.testeeGroup.reduce(function (total, item) {
            return total + item.groupValue + ','
        }, '');
        postData.groupMessage = groupMsg
        addTestee(postData)
         .then(res => {
            this.getTableData()
            this.$message({
                type: 'success',
                showClose: true,
                message: res.data,
                center: true
            });
            this.btnLoading = false
            this.dialogVisibleAdd = false
            this.$store.dispatch('getQueryValue',this.researchCode)
        })
        .catch(error => {
            this.submitButtonDisabled = false;
            this.btnLoading = false
        });
      },
      lookcode(){
        return 'lookCodeStyle'
      },
  },
}

</script>
<style lang='scss' scoped>
.lookCodeStyle{

}
</style>