<template>
  <div id="testeeView">
    <div id="pieChart" ref="pieChart" class="pie-container"></div>
    <div id="barCenterChart" ref="barCenterChart" class="barCenter-container"></div>
    <div id="tableView" class="tableView-container">
      <span class="title" ref="title">各中心受试者入组进度</span>
      <el-table :data="tableData" style="width:100%" :header-cell-class-name="tableHeaderCn" :row-class-name="tableRowClassName" height='calc(100% - 43px)'>
        <el-table-column prop="center_name" label="中心名称" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="expect_case_num" label="计划入组" align="center"></el-table-column>
        <el-table-column prop="count" label="实际入组" align="center"></el-table-column>
        <el-table-column prop="value" label="进度百分比" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="light" :content="Number(scope.row.value == undefined? 0: (scope.row.value>= 100? 100: scope.row.value&& scope.row.value))+''+'%'" placement="top-start">
              <el-progress :show-text="false" :stroke-width="16" color="#5e759c" :percentage="Number(scope.row.value == undefined? 0: (scope.row.value>= 100? 100: scope.row.value&& scope.row.value))"></el-progress>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div id="barMonthChart" ref="barMonthChart" class="barMonth-container"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
let Echarts = require('echarts/lib/echarts');
require("echarts/lib/chart/pie");
require("echarts/lib/chart/bar");

require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require('echarts/lib/component/legend');
require('echarts/lib/component/dataZoom');

import { getPie, getBarCenter, getBarMonth } from "@/api/testeeView";
import {mapState} from 'vuex'

export default {
  name: 'TesteeView',
  components: {
    // HelloWorld
  },
  data(){
    return {
      msg: 'This is TesteeView!!!',
      tableData: []
    }
  },
  computed: {
    ... mapState(['versionData','versionFineshed'])
  },
  watch: {
    versionFineshed(n,o){
      if (n != o) {
        this.getPieData();
        this.getBarCenterData();
        this.getBarMonthData();
      }
    },
  },
  created(){
    this.getPieData();
    this.getBarCenterData();
    this.getBarMonthData();
  },
  methods: {
    getPieData(){
      const getData = {
        researchCode: this.$route.query.researchCode,
        versionCode: JSON.parse(localStorage.getItem('versionCode')),
      };
      getPie(getData)
				.then(res => {
          this.initPie(res.data.returnMap[0]);
				})
				.catch(function(error) {

				});
    },
    getBarCenterData(){
      const getData = {
        researchCode: this.$route.query.researchCode,
        versionCode: JSON.parse(localStorage.getItem('versionCode')),
      };
      getBarCenter(getData)
				.then(res => {
          this.tableData = res.data.resultMap;
          let dataX = [];
          let dataVal = [];
          res.data.resultMap.forEach(item => {
            dataX.push(item.center_name);
            dataVal.push(item.count);
          });
          this.initBarCenter(dataX,dataVal);
				})
				.catch(function(error) {

				});
    },
    getBarMonthData(){
      const getData = {
        researchCode: this.$route.query.researchCode,
        versionCode: JSON.parse(localStorage.getItem('versionCode')),
      };
      getBarMonth(getData)
				.then(res => {
          let dataX = [];
          let dataVal = [];
          res.data.resultMap.forEach(item => {
            dataX.push(item.create_datetime);
            dataVal.push(item.expect_case_num);
          });
          let yearStr = '';
          let dataXHandle = [];
          dataX.forEach(item => {
            dataXHandle.push(item.replace(/\d{4}年/g,function(year,index){
              return year === yearStr ? '' : yearStr = year;
            }));
          })
          this.initBarMonth(dataXHandle,dataVal)
				})
				.catch(function(error) {

				});
    },
    initPie (data) {
      this.$nextTick(() => {
        let pieHeight = this.$refs.pieChart.clientHeight||0;
        let pieWidth = this.$refs.pieChart.clientWidth;
        let pieSize = Math.min(pieHeight,pieWidth);
        this.$refs.title.style.fontSize = (pieSize/18 | 0) + 'px';
        let pieEchart = document.getElementById('pieChart');
        let pieEcharts =  Echarts.init(pieEchart,'memo');
        let option = {
          title: {
            show: true,
            text: '研究受试者入组进度',
            left: pieSize/40 | 0,
            top: pieSize/40 | 0,
            textStyle: {
              fontSize: pieSize/18 | 0,
              color: '#333333',
              fontWeight: 'bold',
            }
          },
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              right: 10,
              formatter: function (name) {
                  return name=='可入组' ? '计划入组: '+data.predict : name + ': '+data.now;
              },
              textStyle: {
                fontSize: pieSize/22 | 0 ,
                color: '#787878',
              },
              data: [{name:'可入组',icon:'none'}, {name:'实际入组',icon:'none'}]
          },
          // color:['#4996FF','#EA961E'],
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  bottom: 10,
                  radius: ['45%', '70%'],
                  avoidLabelOverlap: true,
                  center: ['50%', '55%'],
                  label: {
                      show: true,
                      position: 'center',
                      formatter: function(argument){
                        return '实际入组/计划入组 \n\n '+(data.value ? data.value : 0)+'%'
                      },
                      textStyle: {
                        fontSize: pieSize/22 | 0 ,
                        marginBottom: 10,
                        color: '#787878',
                      }
                  },
                  labelLine: {
                      show: false
                  },
                  data: [
                      {value: data.predict-data.now, name: '可入组'},
                      {value: data.now, name: '实际入组'},
                  ]
              }
          ]
        };
        pieEcharts.setOption(option);
        window.addEventListener("resize", () => { pieEcharts.resize();});
      })
    },
    initBarCenter(dataX,dataVal) {
      this.$nextTick(() => {
        let barCenterHeight = this.$refs.barCenterChart.clientHeight;
        let barCenterWidth = this.$refs.barCenterChart.clientWidth;
        let barCenterSize = Math.min(barCenterHeight,barCenterWidth);
        let barCenterchart = document.getElementById('barCenterChart');
        let barCenterEcharts =  Echarts.init(barCenterchart,'memo');
        let option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'none'
              }
          },
          title: {
            show: true,
            text: '各中心受试者入组数量',
            left: barCenterSize/40 | 0,
            top: barCenterSize/40 | 0,
            textStyle: {
              fontSize: barCenterSize/18 | 0,
              color: '#333333',
              fontWeight: 'bold',
            }
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: '3%',
            top: '15%',
            containLabel: true
          },
          xAxis: {
              type: 'category',
              interval: 30,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle:{
                  color: '#DCDCDC'
                }
              },
              axisLabel: {
                fontSize: 10,
                color: '#999999',
                interval: 0,
                formatter:function(value){
                  //处理x轴文字过长
                  return value
                }
              },
              minorSplitLine: {
                show: false
              },
              data: dataX,
          },
          yAxis: {
              type: 'value',
              minInterval:1,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle:{
                  color: '#DCDCDC'
                }
              },
              splitLine: {
                lineStyle: {
                  color: '#EEEEEE'
                }
              },
              axisLabel: {
                fontSize: 10,
                color: '#999999'
              },
          },
          dataZoom: [
            {
                type: 'inside',
            }
          ],
          series: [{
              data: dataVal,
              type: 'bar',
              barWidth: barCenterWidth/40 | 0,
              // itemStyle: {
              //   color: '#1F92E5'
              // },
              showBackground: true,
              // backgroundStyle: {
              //     color: 'rgba(31,146,229,0.4)'
              // }
          }],
        };
        barCenterEcharts.setOption(option)
        window.addEventListener("resize", () => { barCenterEcharts.resize();});
        let zoomSize = 6;
        barCenterEcharts.on('click', function (params) {
            barCenterEcharts.dispatchAction({
                type: 'dataZoom',
                startValue: dataX[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue: dataX[Math.min(params.dataIndex + zoomSize / 2, dataVal.length - 1)]
            });
        });
      })
    },
    initBarMonth(dataX,dataVal) {
      this.$nextTick(() => {
        let barMonthHeight = this.$refs.barMonthChart.clientHeight;
        let barMonthWidth = this.$refs.barMonthChart.clientWidth;
        let barMonthSize = Math.min(barMonthHeight,barMonthWidth);
        let barMonthchart = document.getElementById('barMonthChart');
        let barMonthEcharts =  Echarts.init(barMonthchart,'memo');
        let option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'none'
              }
          },
          title: {
            show: true,
            text: '月度受试者入组数量',
            left: barMonthSize/40 | 0,
            top: barMonthSize/40 | 0,
            textStyle: {
              fontSize: barMonthSize/27 | 0,
              color: '#333333',
              fontWeight: 'bold',
            }
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: '3%',
            top: '15%',
            containLabel: true
          },
          xAxis: {
              type: 'category',
              interval: 30,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle:{
                  color: '#DCDCDC'
                }
              },
              axisLabel: {
                fontSize: 10,
                color: '#999999',
                interval: 0,
                formatter:function(value){
                  //处理x轴文字过长
                  return value
                }
              },
              minorSplitLine: {
                show: false
              },
              data: dataX,
          },
          yAxis: {
              type: 'value',
              minInterval:1,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle:{
                  color: '#DCDCDC'
                }
              },
              splitLine: {
                lineStyle: {
                  color: '#EEEEEE'
                }
              },
              axisLabel: {
                fontSize: 10,
                color: '#999999'
              },
          },
          series: [{
              data: dataVal,
              type: 'bar',
              barWidth: barMonthWidth/40 | 0,
              // itemStyle: {
              //   color: '#1F92E5'
              // },
              barGap: '-50%',
              barCategoryGap: 20,
          }],
          dataZoom: [
              {
                  type: 'inside'
              }
          ],
        };
        barMonthEcharts.setOption(option);
        window.addEventListener("resize", () => { barMonthEcharts.resize();});
        let zoomSize = 4;
        barMonthEcharts.on('click', function (params) {
            barMonthEcharts.dispatchAction({
                type: 'dataZoom',
                startValue: dataX[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue: dataX[Math.min(params.dataIndex + zoomSize / 2, dataVal.length - 1)]
            });
        });
      })
    },
    tableHeaderCn() {
      return "testeeView-table_header";
    },
    tableRowClassName({row, rowIndex}){
      return rowIndex%2 ? 'even-row' : ''
    }
  },
  beforeDestroy(){

  }
}
</script>

<style scoped lang="scss">
	#testeeView{
		position: absolute;
    top: 102px;
    bottom: 16px;
    left: 16px;
    right: 16px;
		background: #fff;
    min-width: 720px;
    .pie-container{
      display: inline-block;
      width: 29%;
      height: 39%;
      border: 1px solid #E5E5E5;
    }
    .barCenter-container{
      display: inline-block;
      margin-left: 1%;
      width: 69%;
      height: 39%;
      border: 1px solid #E5E5E5;
    }
    .tableView-container{
      display: inline-block;
      width: 29%;
      height: 59%;
      border: 1px solid #E5E5E5;
      overflow-y: auto;
      .title{
        display: inline-block;
        // font-size: 13px;
        // line-height: 13px;
        margin: 10px 0 20px 10px;
        font-weight:bold;
      }
    }
    .barMonth-container{
      display: inline-block;
      margin-left: 1%;
      width: 69%;
      height: 59%;
      border: 1px solid #E5E5E5;
    }
	}
</style>
<style lang="scss">
.testeeView-table_header {
  background-color: #e9ecef !important;
  border-right: none !important;
  line-height: 32px;
  .cell{
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }
}
#testeeView .el-table .cell{
  line-height: 20px;
}
.even-row{
  background-color:rgba(30,149,234,0.05) !important;
}
.el-progress-bar__outer{
  background-color:rgba(18,146,239,0.3) !important;
}
</style>