import Vue from 'vue'
import request from './axios'
//研究信息编辑
export function updateResearch(data) { // 
  return request({
    url: '/api/tSiteResearch/updateBean',
    method: 'POST',
    data,
  })
}
//受试者查询
export function getVisit(params) { // 
  return request({
    url: '/api/tSiteResearch/editPatientModel',
    method: 'GET',
    params,
  })
}
//受试者编辑
export function updateVisit(data) { // 
  return request({
    url: '/api/tSiteResearch/addPatientModel',
    method: 'POST',
    data,
  })
}
//受试者新增组
export function addVisitGroup(data) { // 
  return request({
    url: '/api/tSiteResearch/saveGroup',
    method: 'POST',
    data,
  })
}
//受试者删除组
export function deleteVisitGroup(data) { // 
  return request({
    url: '/api/tSiteResearch/deletePatientGroup',
    method: 'POST',
    data,
  })
}
//获取二维码
export function getQrCode (researchCode){
  return request({
    url:'api/weixin/recruitQrcodeUrl',
    method:'POST',
    params:{
      researchCode
    }
  })
}
//获取招募中心
export function getInfo(researchCode){
  return request({
    url:'api/weixin/seeRecruit',
    method:'POST',
    params:{
      researchCode
    }
  })
}
//保存招募信息
export function saveRecruit(data){
  return request({
    url:'api/weixin/saveRecruit',
    method:'POST',
    data
  })
}



  //月份柱状图
// export function getBarMonth(params) { // 
//   return request({
//     url: '/api/homepage/ShowCenterPatientTime',
//     method: 'GET',
//     params,
//   })
// }